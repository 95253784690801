import axios from "axios";
import { authProvider } from "../Auth/authProvider";
import configData from "../config.json";

const instance = axios.create({
  baseURL: "https://" + configData.API.Server + configData.API.Path,
});

instance.interceptors.request.use(async function (config) {
  const token = await authProvider.getIdToken();
  const idToken = token.idToken.rawIdToken;
  // const accessToken = await authProvider.getAccessToken();
  // const access = accessToken.accessToken;
  config.headers.Authorization = `Bearer ${idToken}`;
  config.headers.Access = idToken;

  return config;
});
export default instance;
